/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Smmoth scroll
          $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });

        // Resize Header on scroll
            var header = $(".header");
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();

                if (scroll > 0) {
                    header.addClass("scrolling");
                } else {
                    header.removeClass("scrolling");
                }
            });


        // toggles hamburger and nav open and closed states
        var removeClass = true;
          $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function() {
          if (removeClass) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          // removeClass = true;
        }, false);

        // Scroll up
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        // Screen Size for swipe icon
        var screenSize;

          function checkSize() {
            if($('.test-size').css('font-size') === '1px') {
              screenSize = 'xs';
            } else if($('.test-size').css('font-size') === '2px') {
              screenSize = 'sm';
            } else if($('.test-size').css('font-size') === '3px') {
              screenSize = 'md';
            } else if($('.test-size').css('font-size') === '4px') {
              screenSize = 'lg';
            }
          }

          checkSize();

          $(window).resize(function(){
            checkSize();
          });

          function isScrolledIntoView(elem)
          {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();
            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop) && docViewTop !== 0);
          }

          var showSwipe = false;

          $(window).scroll(function() {
            if($('.swipicon-container').length) {
              if(isScrolledIntoView($('.swipicon-container')) && !$('.swipicon').hasClass('swipiconActive') && !showSwipe && (screenSize === 'xs' || screenSize === 'sm')) {
                showSwipe = true;
                $('.swipicon').fadeIn(1500,function(){
                  $('.swipicon').addClass('swipiconActive');
                }).delay(1600).fadeOut(1500);
              }
            }
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_has_map': {
      init: function() {
          // Google Maps
          var map;

           function initialize_gmap(bound) {
             var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

             var isDraggable = w > 480 ? true : false;

             var mapCenter;

             if (w > 540) {
               mapCenter = {lat: 44.710996, lng: -70.790657};
             } else {
               mapCenter = bound.getCenter();
             }

             var settings = {
               zoom: 7,
               center: mapCenter,
               scrollwheel: false,
               draggable: isDraggable,
               mapTypeControl: false,
               mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
               navigationControl: false,
               navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
               mapTypeId: google.maps.MapTypeId.ROADMAP,
               styles: [
                    {"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},
                    {"featureType":"administrative.country","elementType":"labels","stylers":[{"visibility":"off"}]},
                    {"featureType":"administrative.province","elementType":"labels","stylers":[{"visibility":"off"}]},
                    {"featureType":"administrative.locality","elementType":"labels","stylers":[{"visibility":"off"}]},
                    {"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},
                    {"featureType":"landscape.natural.landcover","elementType":"geometry.fill","stylers":[{"visibility":"off"},{"color":"#ff0000"}]},
                    {"featureType":"landscape.natural.landcover","elementType":"labels","stylers":[{"visibility":"off"}]},
                    {"featureType":"landscape.natural.terrain","elementType":"geometry","stylers":[{"visibility":"off"}]},
                    {"featureType":"landscape.natural.terrain","elementType":"geometry.fill","stylers":[{"visibility":"off"},{"saturation":"69"},{"lightness":"97"},{"gamma":"3.61"},{"color":"#a8a8a8"}]},
                    {"featureType":"landscape.natural.terrain","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},
                    {"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},
                    {"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"visibility":"off"},{"saturation":"-80"},{"lightness":"46"},{"color":"#d8d8d8"}]},
                    {"featureType":"poi.park","elementType":"labels.text","stylers":[{"visibility":"off"}]},
                    {"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#4a4a4a"},{"visibility":"off"}]},
                    {"featureType":"poi.park","elementType":"labels.icon","stylers":[{"visibility":"off"}]},
                    {"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}] },
                    {"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},
                    {"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"simplified"}]},
                    {"featureType":"road.arterial","elementType":"labels","stylers":[{"visibility":"off"}]},
                    {"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},
                    {"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},
                    {"featureType":"water","elementType":"all","stylers":[{"color":"#5b8292"},{"visibility":"on"}]},
                    {"featureType":"water","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#797d9c"}]},
                    {"featureType":"water","elementType":"labels.text","stylers":[{"visibility":"on"}]}
                ]
             };

             map = new google.maps.Map(document.getElementById("map_canvas"), settings);

             var center;
             function calculateCenter() {
               center = map.getCenter();
             }
             google.maps.event.addDomListener(map, 'idle', function() {
               calculateCenter();
             });
             google.maps.event.addDomListener(window, 'resize', function() {
               map.setCenter();
             });

             var maineMapPolygon = [
               new google.maps.LatLng(45.298075,-71.092529),
               new google.maps.LatLng(45.251688,-71.130981),
               new google.maps.LatLng(45.251688,-71.169434),
               new google.maps.LatLng(45.243953,-71.185913),
               new google.maps.LatLng(45.243953,-71.196899),
               new google.maps.LatLng(45.251688,-71.235352),
               new google.maps.LatLng(45.267155,-71.257324),
               new google.maps.LatLng(45.286482,-71.262817),
               new google.maps.LatLng(45.301939,-71.290283),
               new google.maps.LatLng(45.274886,-71.328735),
               new google.maps.LatLng(45.263289,-71.361694),
               new google.maps.LatLng(45.240086,-71.361694),
               new google.maps.LatLng(45.240086,-71.394653),
               new google.maps.LatLng(45.240086,-71.455078),
               new google.maps.LatLng(45.205263,-71.411133),
               new google.maps.LatLng(45.139430,-71.444092),
               new google.maps.LatLng(45.131680,-71.438599),
               new google.maps.LatLng(45.077400,-71.504517),
               new google.maps.LatLng(45.050240,-71.499023),
               new google.maps.LatLng(44.999767,-71.504517),
               new google.maps.LatLng(44.988113,-71.531982),
               new google.maps.LatLng(44.964798,-71.510010),
               new google.maps.LatLng(44.929808,-71.515503),
               new google.maps.LatLng(44.902578,-71.504517),
               new google.maps.LatLng(44.871443,-71.537476),
               new google.maps.LatLng(44.840291,-71.553955),
               new google.maps.LatLng(44.820812,-71.564941),
               new google.maps.LatLng(44.789633,-71.570435),
               new google.maps.LatLng(44.742832,-71.630859),
               new google.maps.LatLng(44.727223,-71.636353),
               new google.maps.LatLng(44.703802,-71.603394),
               new google.maps.LatLng(44.656932,-71.559448),
               new google.maps.LatLng(44.613934,-71.553955),
               new google.maps.LatLng(44.590467,-71.553955),
               new google.maps.LatLng(44.582643,-71.531982),
               new google.maps.LatLng(44.551335,-71.581421),
               new google.maps.LatLng(44.512176,-71.581421),
               new google.maps.LatLng(44.484749,-71.603394),
               new google.maps.LatLng(44.469071,-71.641846),
               new google.maps.LatLng(44.425934,-71.680298),
               new google.maps.LatLng(44.402392,-71.740723),
               new google.maps.LatLng(44.398467,-71.784668),
               new google.maps.LatLng(44.374913,-71.812134),
               new google.maps.LatLng(44.347422,-71.823120),
               new google.maps.LatLng(44.331707,-71.889038),
               new google.maps.LatLng(44.339565,-71.916504),
               new google.maps.LatLng(44.331707,-71.982422),
               new google.maps.LatLng(44.312057,-72.026367),
               new google.maps.LatLng(44.296333,-72.048340),
               new google.maps.LatLng(44.264871,-72.064819),
               new google.maps.LatLng(44.221584,-72.059326),
               new google.maps.LatLng(44.134913,-72.053833),
               new google.maps.LatLng(44.099421,-72.053833),
               new google.maps.LatLng(44.079693,-72.048340),
               new google.maps.LatLng(44.040219,-72.086792),
               new google.maps.LatLng(44.016521,-72.108765),
               new google.maps.LatLng(43.973052,-72.114258),
               new google.maps.LatLng(43.949327,-72.103271),
               new google.maps.LatLng(43.909766,-72.136230),
               new google.maps.LatLng(43.846413,-72.196655),
               new google.maps.LatLng(43.806783,-72.191162),
               new google.maps.LatLng(43.759193,-72.218628),
               new google.maps.LatLng(43.731414,-72.279053),
               new google.maps.LatLng(43.683764,-72.317505),
               new google.maps.LatLng(43.640051,-72.322998),
               new google.maps.LatLng(43.584370,-72.361450),
               new google.maps.LatLng(43.552529,-72.388916),
               new google.maps.LatLng(43.532620,-72.388916),
               new google.maps.LatLng(43.508721,-72.388916),
               new google.maps.LatLng(43.468868,-72.399902),
               new google.maps.LatLng(43.413029,-72.410889),
               new google.maps.LatLng(43.373112,-72.421875),
               new google.maps.LatLng(43.349150,-72.394409),
               new google.maps.LatLng(43.333169,-72.416382),
               new google.maps.LatLng(43.297198,-72.405396),
               new google.maps.LatLng(43.269206,-72.427368),
               new google.maps.LatLng(43.221190,-72.449341),
               new google.maps.LatLng(43.161116,-72.454834),
               new google.maps.LatLng(43.100983,-72.443848),
               new google.maps.LatLng(43.048820,-72.465820),
               new google.maps.LatLng(43.024730,-72.465820),
               new google.maps.LatLng(42.968482,-72.471313),
               new google.maps.LatLng(42.960443,-72.509766),
               new google.maps.LatLng(42.936318,-72.526245),
               new google.maps.LatLng(42.855833,-72.548218),
               new google.maps.LatLng(42.811522,-72.548218),
               new google.maps.LatLng(42.783307,-72.515259),
               new google.maps.LatLng(42.763146,-72.493286),
               new google.maps.LatLng(42.730874,-72.471313),
               new google.maps.LatLng(42.694549,-71.312256),
               new google.maps.LatLng(42.747012,-71.257324),
               new google.maps.LatLng(42.747012,-71.185913),
               new google.maps.LatLng(42.791370,-71.185913),
               new google.maps.LatLng(42.823610,-71.125488),
               new google.maps.LatLng(42.815551,-71.070557),
               new google.maps.LatLng(42.835696,-71.054077),
               new google.maps.LatLng(42.851806,-71.032104),
               new google.maps.LatLng(42.871938,-70.949707),
               new google.maps.LatLng(42.875964,-70.894775),
               new google.maps.LatLng(42.863886,-70.845337),
               new google.maps.LatLng(42.875964,-70.812378),
               new google.maps.LatLng(42.980540,-70.762939),
               new google.maps.LatLng(43.052834,-70.702515),
               new google.maps.LatLng(43.121034,-70.620117),
               new google.maps.LatLng(43.157109,-70.614624),
               new google.maps.LatLng(43.205176,-70.581665),
               new google.maps.LatLng(43.233197,-70.576172),
               new google.maps.LatLng(43.245203,-70.576172),
               new google.maps.LatLng(43.269206,-70.603638),
               new google.maps.LatLng(43.293200,-70.576172),
               new google.maps.LatLng(43.333169,-70.526733),
               new google.maps.LatLng(43.341160,-70.460815),
               new google.maps.LatLng(43.385090,-70.416870),
               new google.maps.LatLng(43.413029,-70.367432),
               new google.maps.LatLng(43.440955,-70.350952),
               new google.maps.LatLng(43.476840,-70.383911),
               new google.maps.LatLng(43.532620,-70.339966),
               new google.maps.LatLng(43.532620,-70.307007),
               new google.maps.LatLng(43.548548,-70.274048),
               new google.maps.LatLng(43.540585,-70.241089),
               new google.maps.LatLng(43.556510,-70.230103),
               new google.maps.LatLng(43.560491,-70.197144),
               new google.maps.LatLng(43.592328,-70.213623),
               new google.maps.LatLng(43.628123,-70.202637),
               new google.maps.LatLng(43.659924,-70.180664),
               new google.maps.LatLng(43.691708,-70.087280),
               new google.maps.LatLng(43.719505,-69.999390),
               new google.maps.LatLng(43.794889,-69.922485),
               new google.maps.LatLng(43.771094,-69.889526),
               new google.maps.LatLng(43.703622,-69.856567),
               new google.maps.LatLng(43.743321,-69.779663),
               new google.maps.LatLng(43.782993,-69.664307),
               new google.maps.LatLng(43.810747,-69.598389),
               new google.maps.LatLng(43.834527,-69.505005),
               new google.maps.LatLng(43.937462,-69.428101),
               new google.maps.LatLng(43.917680,-69.257813),
               new google.maps.LatLng(43.941417,-69.197388),
               new google.maps.LatLng(43.984910,-69.186401),
               new google.maps.LatLng(43.996766,-69.153442),
               new google.maps.LatLng(44.036270,-69.076538),
               new google.maps.LatLng(44.024422,-68.862305),
               new google.maps.LatLng(44.000718,-68.653564),
               new google.maps.LatLng(44.020472,-68.615112),
               new google.maps.LatLng(44.107310,-68.340454),
               new google.maps.LatLng(44.245199,-68.203125),
               new google.maps.LatLng(44.335636,-68.065796),
               new google.maps.LatLng(44.355278,-68.043823),
               new google.maps.LatLng(44.398467,-68.010864),
               new google.maps.LatLng(44.382766,-67.983398),
               new google.maps.LatLng(44.394542,-67.901001),
               new google.maps.LatLng(44.425934,-67.851563),
               new google.maps.LatLng(44.496505,-67.708740),
               new google.maps.LatLng(44.449468,-67.587891),
               new google.maps.LatLng(44.457310,-67.565918),
               new google.maps.LatLng(44.496505,-67.510986),
               new google.maps.LatLng(44.606113,-67.439575),
               new google.maps.LatLng(44.590467,-67.395630),
               new google.maps.LatLng(44.610023,-67.384644),
               new google.maps.LatLng(44.617844,-67.368164),
               new google.maps.LatLng(44.594379,-67.307739),
               new google.maps.LatLng(44.606113,-67.274780),
               new google.maps.LatLng(44.641300,-67.186890),
               new google.maps.LatLng(44.672559,-67.126465),
               new google.maps.LatLng(44.727223,-67.104492),
               new google.maps.LatLng(44.738930,-67.077026),
               new google.maps.LatLng(44.766237,-67.066040),
               new google.maps.LatLng(44.766237,-67.038574),
               new google.maps.LatLng(44.801327,-66.972656),
               new google.maps.LatLng(44.816916,-66.939697),
               new google.maps.LatLng(44.840291,-66.978149),
               new google.maps.LatLng(44.902578,-66.972656),
               new google.maps.LatLng(45.100669,-67.104492),
               new google.maps.LatLng(45.158801,-67.159424),
               new google.maps.LatLng(45.166547,-67.197876),
               new google.maps.LatLng(45.158801,-67.230835),
               new google.maps.LatLng(45.185909,-67.274780),
               new google.maps.LatLng(45.147179,-67.302246),
               new google.maps.LatLng(45.131680,-67.335205),
               new google.maps.LatLng(45.154927,-67.373657),
               new google.maps.LatLng(45.178165,-67.406616),
               new google.maps.LatLng(45.216873,-67.445068),
               new google.maps.LatLng(45.240086,-67.461548),
               new google.maps.LatLng(45.278752,-67.489014),
               new google.maps.LatLng(45.294211,-67.500000),
               new google.maps.LatLng(45.290347,-67.461548),
               new google.maps.LatLng(45.321254,-67.456055),
               new google.maps.LatLng(45.352145,-67.439575),
               new google.maps.LatLng(45.379161,-67.412109),
               new google.maps.LatLng(45.410020,-67.445068),
               new google.maps.LatLng(45.448571,-67.483521),
               new google.maps.LatLng(45.483244,-67.500000),
               new google.maps.LatLng(45.502497,-67.478027),
               new google.maps.LatLng(45.498647,-67.417603),
               new google.maps.LatLng(45.525592,-67.428589),
               new google.maps.LatLng(45.567910,-67.417603),
               new google.maps.LatLng(45.598666,-67.445068),
               new google.maps.LatLng(45.598666,-67.483521),
               new google.maps.LatLng(45.594822,-67.505493),
               new google.maps.LatLng(45.606352,-67.609863),
               new google.maps.LatLng(45.625563,-67.631836),
               new google.maps.LatLng(45.621722,-67.659302),
               new google.maps.LatLng(45.683158,-67.708740),
               new google.maps.LatLng(45.686996,-67.730713),
               new google.maps.LatLng(45.667805,-67.730713),
               new google.maps.LatLng(45.675482,-67.763672),
               new google.maps.LatLng(45.725356,-67.807617),
               new google.maps.LatLng(45.733025,-67.780151),
               new google.maps.LatLng(45.763691,-67.807617),
               new google.maps.LatLng(45.809658,-67.796631),
               new google.maps.LatLng(45.824971,-67.758179),
               new google.maps.LatLng(45.874712,-67.802124),
               new google.maps.LatLng(45.897655,-67.774658),
               new google.maps.LatLng(45.912944,-67.758179),
               new google.maps.LatLng(45.947330,-67.780151),
               new google.maps.LatLng(47.062638,-67.791138),
               new google.maps.LatLng(47.100045,-67.890015),
               new google.maps.LatLng(47.156105,-67.917480),
               new google.maps.LatLng(47.208374,-67.961426),
               new google.maps.LatLng(47.204642,-67.994385),
               new google.maps.LatLng(47.275502,-68.082275),
               new google.maps.LatLng(47.309034,-68.153687),
               new google.maps.LatLng(47.323931,-68.159180),
               new google.maps.LatLng(47.357432,-68.236084),
               new google.maps.LatLng(47.357432,-68.302002),
               new google.maps.LatLng(47.349989,-68.373413),
               new google.maps.LatLng(47.327654,-68.378906),
               new google.maps.LatLng(47.309034,-68.389893),
               new google.maps.LatLng(47.286682,-68.433838),
               new google.maps.LatLng(47.297859,-68.488770),
               new google.maps.LatLng(47.297859,-68.527222),
               new google.maps.LatLng(47.282956,-68.576660),
               new google.maps.LatLng(47.260592,-68.615112),
               new google.maps.LatLng(47.238219,-68.648071),
               new google.maps.LatLng(47.238219,-68.708496),
               new google.maps.LatLng(47.227029,-68.779907),
               new google.maps.LatLng(47.208374,-68.818359),
               new google.maps.LatLng(47.189712,-68.873291),
               new google.maps.LatLng(47.178512,-68.906250),
               new google.maps.LatLng(47.204642,-68.939209),
               new google.maps.LatLng(47.204642,-68.961182),
               new google.maps.LatLng(47.241949,-69.038086),
               new google.maps.LatLng(47.368594,-69.060059),
               new google.maps.LatLng(47.379754,-69.049072),
               new google.maps.LatLng(47.402067,-69.032593),
               new google.maps.LatLng(47.424371,-69.043579),
               new google.maps.LatLng(47.431803,-69.065552),
               new google.maps.LatLng(47.431803,-69.104004),
               new google.maps.LatLng(47.450380,-69.147949),
               new google.maps.LatLng(47.461523,-69.180908),
               new google.maps.LatLng(47.450380,-69.219360),
               new google.maps.LatLng(47.450380,-69.246826),
               new google.maps.LatLng(46.698435,-69.999390),
               new google.maps.LatLng(46.403776,-70.070801),
               new google.maps.LatLng(46.373464,-70.136719),
               new google.maps.LatLng(46.354511,-70.186157),
               new google.maps.LatLng(46.331758,-70.219116),
               new google.maps.LatLng(46.297611,-70.219116),
               new google.maps.LatLng(46.259645,-70.252075),
               new google.maps.LatLng(46.206448,-70.279541),
               new google.maps.LatLng(46.183634,-70.296021),
               new google.maps.LatLng(46.141783,-70.246582),
               new google.maps.LatLng(46.099900,-70.257568),
               new google.maps.LatLng(46.099900,-70.296021),
               new google.maps.LatLng(46.069420,-70.312500),
               new google.maps.LatLng(46.054173,-70.290527),
               new google.maps.LatLng(46.019853,-70.312500),
               new google.maps.LatLng(45.989329,-70.290527),
               new google.maps.LatLng(45.966425,-70.323486),
               new google.maps.LatLng(45.954969,-70.268555),
               new google.maps.LatLng(45.935871,-70.241089),
               new google.maps.LatLng(45.920587,-70.263062),
               new google.maps.LatLng(45.893831,-70.263062),
               new google.maps.LatLng(45.851760,-70.328979),
               new google.maps.LatLng(45.828799,-70.367432),
               new google.maps.LatLng(45.798170,-70.411377),
               new google.maps.LatLng(45.752193,-70.405884),
               new google.maps.LatLng(45.725356,-70.378418),
               new google.maps.LatLng(45.698507,-70.455322),
               new google.maps.LatLng(45.667805,-70.537720),
               new google.maps.LatLng(45.660127,-70.570679),
               new google.maps.LatLng(45.614037,-70.625610),
               new google.maps.LatLng(45.602509,-70.664063),
               new google.maps.LatLng(45.575600,-70.669556),
               new google.maps.LatLng(45.564064,-70.697021),
               new google.maps.LatLng(45.548679,-70.691528),
               new google.maps.LatLng(45.517895,-70.729980),
               new google.maps.LatLng(45.487095,-70.718994),
               new google.maps.LatLng(45.467836,-70.691528),
               new google.maps.LatLng(45.444717,-70.680542),
               new google.maps.LatLng(45.421588,-70.625610),
               new google.maps.LatLng(45.394593,-70.636597),
               new google.maps.LatLng(45.383019,-70.664063),
               new google.maps.LatLng(45.394593,-70.686035),
               new google.maps.LatLng(45.390735,-70.708008),
               new google.maps.LatLng(45.413876,-70.746460),
               new google.maps.LatLng(45.425444,-70.751953),
               new google.maps.LatLng(45.425444,-70.795898),
               new google.maps.LatLng(45.398450,-70.834351),
               new google.maps.LatLng(45.359865,-70.806885),
               new google.maps.LatLng(45.332840,-70.823364),
               new google.maps.LatLng(45.313529,-70.812378),
               new google.maps.LatLng(45.290347,-70.834351),
               new google.maps.LatLng(45.271021,-70.845337),
               new google.maps.LatLng(45.240086,-70.839844),
               new google.maps.LatLng(45.232349,-70.889282),
               new google.maps.LatLng(45.263289,-70.916748),
               new google.maps.LatLng(45.290347,-70.922241),
               new google.maps.LatLng(45.309666,-70.916748),
               new google.maps.LatLng(45.325117,-70.944214),
               new google.maps.LatLng(45.340563,-70.949707),
               new google.maps.LatLng(45.336702,-70.971680),
               new google.maps.LatLng(45.352145,-71.010132),
               new google.maps.LatLng(45.321254,-71.010132),
               new google.maps.LatLng(45.313529,-71.043091),
               new google.maps.LatLng(45.301939,-71.081543)
             ];

             var maineMap = new google.maps.Polygon({
               path: maineMapPolygon,
               strokeWeight: 0,
               fillColor: '#237745',
               fillOpacity: 0.5
             });

             maineMap.setMap(map);
           }

           function addMarker(point_lat, point_long, address, title, directions) {
             var infowindow = new google.maps.InfoWindow();
             var point = new google.maps.LatLng(point_lat, point_long);

             var contentString = '<div id="gmapContent" style="text-align:left;">'+
               '<a href="' + directions + '" target="_blank"><h4>' + title + '</h4><small>(Click For Directions)</small></a>'+
               '<div id="bodyContent">'+
               '<p>' + address + '</p>'+
               '</div>'+
               '</div>';

             var companyMarker = new google.maps.Marker({
               position: point,
               map: map,
               label: {
                  color: 'white',
                  fontWeight: 'bold',
                  fontFamily: 'ingra,sans-serif',
                  fontSize: '16px',
                  text: title,
                },
                icon: {
                  labelOrigin: new google.maps.Point(11, 50),
                  url: '/wp-content/uploads/images/map-point-ico.png',
                  size: new google.maps.Size(27, 40),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(11, 40),
                },
               title: title});

             google.maps.event.addListener(companyMarker, 'click', function() {
               infowindow.setContent(contentString);
               infowindow.open(map,this);
             });

           }

           document.initMap = function() {
               var locations = [];

               $('.location-map').each(function() {
                 var location = [];
                 location.push(parseFloat($(this).find('.map-data').find('.point_lat').data('pointlat')));
                 location.push(parseFloat($(this).find('.map-data').find('.point_long').data('pointlong')));
                 location.push($(this).find('.map-data').find('.address').data('address'));
                 location.push($(this).find('.map-data').find('.title').data('title'));
                 location.push($(this).find('.map-data').find('.directions').data('directions'));
                 locations.push(location);
               });

               if($('#map_canvas').length) {
                 var bound = new google.maps.LatLngBounds();
                 for (i = 0; i < locations.length; i++) {
                   bound.extend( new google.maps.LatLng(locations[i][0], locations[i][1]) );
                 }
                 initialize_gmap(bound);
                 for (i = 0; i < locations.length; i++) {
                   addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3], locations[i][4]);
                 }
               }
           };

           var maps_api_key = 'AIzaSyDsR-WfGTE__CatsHriMZNicnwSaHTNwiM';
           var maps_callback = 'document.initMap';
           var maps_api_url = '//maps.google.com/maps/api/js';
           var maps_script_tag = document.createElement('script');
           maps_script_tag.setAttribute('type', 'text/javascript');
           maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
           maps_script_tag.setAttribute('defer', '');
           maps_script_tag.setAttribute('async', '');
           document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
